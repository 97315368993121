import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import { DialogContentImportScorm } from '../dialog-content-import-scorm/dialog-content-import-scorm';

@Component({
  selector: 'app-module',
  templateUrl: './module.component.html',
  styleUrls: ['./module.component.scss']
})
export class ModuleComponent implements OnInit {

  cards = [];
  isDeleteActive = false;

  constructor(
    private dataservice: DataService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    const user = JSON.parse(localStorage.getItem("user"));
    let coursesGetted = true;
    if (coursesGetted) {
      coursesGetted = false;
      this.dataservice.getAllModules(user.idUser).subscribe(
        data => {
          console.log(Object.values(data));
          this.cards = Object.values(data);
          this.cards.pop();
          this.cards.pop();
          this.cards = this.cards.filter(card => {
            if (card.firstUploadDate !== "") {
              return card;
            }
          });
          this.cards = this.cards.sort((a, b) => {
            let dateA = new Date(a.firstUploadDate);
            let dateB = new Date(b.firstUploadDate);
            if (dateA < dateB)
              return 1;
            if (dateA > dateB)
              return -1;
            return 0;
          })
          coursesGetted = true;
        }
      )
    }
  }

  replace(moduleId) {
    this.alertImport(moduleId)
  }

  alertImport(moduleId = "") {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
        id: moduleId,
    };

    const dialogRef = this.dialog.open(DialogContentImportScorm, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });

  }

  toggleDeleteStatus() {
    this.isDeleteActive = !this.isDeleteActive;
  }

  delete(id) {
    this.dataservice.deleteModule(id).subscribe(
      data => console.log(`Module number ${id} succefully deleted !`)
    )
  }

}
