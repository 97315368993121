import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../data.service';

@Component({
  selector: 'app-external-connect',
  templateUrl: './external-connect.component.html',
  styleUrls: ['./external-connect.component.scss']
})
export class ExternalConnectComponent implements OnInit {

  constructor(
    private dataService: DataService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      console.log("PARAMS: ", params)
      this.dataService.getMatriculeUser(params["l"], params["k"]).subscribe(
        data => {
          console.log(data)
          if(data["m_idUser"]) {
            const user = {
              email: data["m_Mail"],
              username: data["m_UserName"],
              surname: data["m_UserSurname"],
              idUser: data["m_idUser"],
              role: data["m_Level"]
            }
            if (user.surname === "Dev") {
              user.role = "Manager"
            }
            localStorage.setItem("user", JSON.stringify(user));
            this.router.navigate(['/home']);
          }
        }
      )
    });
  }

}
