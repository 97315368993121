import { Component, OnInit, ɵConsole } from '@angular/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { ThemePalette } from '@angular/material/core';
import { Router, NavigationEnd } from '@angular/router';
import { DataService } from '../data.service';
import { ManagerGuard } from '../manager.guard';
import { SearchService } from '../search.service';

/**
 * @title Basic cards
 */

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  cards = [];
  notifications = [];
  value = 10;

  user = undefined;

  currentPage = 'parcours';
  finishedCourses = 0;
  inProgressCourses = 0;
  barValue = 0;
  finalText = "";

  loadedNotifications = false;

  constructor(
    private router: Router,
    private data: DataService,
    private managerGuard: ManagerGuard,
    private searchService: SearchService
    ) { }

  ngOnInit() {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.currentPage = val['url'];
      }
    });
    this.user = JSON.parse(localStorage.getItem("user"));
    if (!this.user) {
      this.logout();
    }
    // }, 500);
  }
  logout() {
    localStorage.removeItem('user');
    this.router.navigate(['/login']);
  }

  isSelected(uri: string) {
    return this.currentPage === uri;
  }

  navigate(uri: string) {
    this.currentPage = uri;
  }

  isManager() {
    return JSON.parse(localStorage.getItem("user"))["role"] === "Manager";
  }

  isAdmin() {
    return JSON.parse(localStorage.getItem("user"))["role"] === "Admin";
  }

  searchText(searchValue) {
    this.searchService.text.emit(searchValue)
  }
   menu() {
    var x = document.getElementById("myTopnav");
    if (x.className === "side-menu") {
      x.className += "responsive";
    } else {
      x.className = "side-menu";
    }
  }
  menuhide(){
    var x = document.getElementById("myTopnav");
    if (x.className === "side-menuresponsive") {
       x.className = "side-menu";
    }
  }
}
