import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { DataService } from '../data.service';
import { export_user_form } from '../teams/teams.component';

@Component({
  selector: 'dialog-content-add-user',
  templateUrl: './dialog-content-add-user.html',
  styleUrls: ['./dialog-content-add-user.scss']
})
export class DialogContentAddUser {

  form_user: export_user_form
  sessions: any
  teams: any
  edition_mode: string
  titlePrefix: string

  genders: Array<string> = ["male", "female"];
  languages: Array<string> = ["French", "German", 'English', 'Russian', 'Arabic', 'Dutch', 'Spanish', 'Polish', 'Portuguese', 'Bulgarian'];
  roles: Array<any> = [
    {
      "name": "Administrator",
      "id": 17
    }, {
      "name": "User",
      "id": 15
    }, {
      "name": "Manager",
      "id": 16
    }, {
      "name": "Manager plus",
      "id": 18
    }
  ];

  constructor(
    private dataService: DataService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.form_user = data.form_user
    this.sessions = data.sessions
    this.edition_mode = data.edition_mode
    this.teams = data.teams
    this.titlePrefix = this.edition_mode === 'update' ? "dialog-add-user.modify" : "dialog-add-user.create"
    console.log(this.titlePrefix, this.edition_mode);
    
  }

  checkMissingInformations() {
    if (this.form_user.name !== "") {
      //console.log("surname missing")
      if (this.form_user.surname !== "") {
        //console.log("email missing")
        if (this.form_user.email !== "") {
          //console.log("company missing")
          if (this.form_user.company !== "") {
            return true
          }
        }
      }
    }
    //console.log("missiing informations")
    return false
  }

  updateUser(): void {
    if (this.checkMissingInformations()) {
      console.log(this.form_user)
      this.dataService.updateUser(this.form_user).subscribe(
        data => {
          console.log(data)
        }
      );
    }
  }

  createUser(elem?: export_user_form): void {
    if (this.checkMissingInformations()) {
      this.dataService.createUser( !elem ? this.form_user : elem).subscribe(
        data => {
          //  console.log(data)
        });
    }
  }
}