import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  @Input() list = new Input();
  @Input() position = new Input();
  @Output() item_selected = new EventEmitter();

  filter_string : string = "";

  constructor() { }

  ngOnInit() {

    console.log(this.list);
    console.log(this.position)

  }

  selectItem(elem) {

    console.log(elem)
    this.item_selected.emit(elem);

  }

  getPosition() {
    switch(this.position) {
      case "right":
        return 'right'
      case "left":
        return 'left';
    }
  }

  typeOfObject(obj : any) {
    if (obj.title !== undefined) {
      return 'title'
    }
    else if (obj.name !== undefined) {
      return 'name'
    }
  }

  filtering( obj : any) : boolean {

    switch(this.typeOfObject(obj)) {
      case 'title':
        return obj.title.toUpperCase().includes(this.filter_string.toUpperCase());
      case 'name' :
        return obj.name.toUpperCase().includes(this.filter_string.toUpperCase())

    }

  }

  getText() {
    if (this.list[0] !== undefined) {
      if (this.list[0].title !== undefined) {
        return 'teams.courses'
      }
      else if (this.list[0].name !== undefined) {
        return 'teams.dedicated_sessions'
      }
    }
  }  
               
}
