import { Component, OnInit, Output, EventEmitter, HostListener, OnDestroy } from '@angular/core';
import { throwMatDialogContentAlreadyAttachedError } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MultiWindowService, WindowData, Message } from 'ngx-multi-window';
import { timeout } from 'rxjs/operators';

@Component({
  selector: 'app-startcourse',
  templateUrl: './startcourse.component.html',
  styleUrls: ['./startcourse.component.scss']
})
export class StartcourseComponent implements OnInit {

  broadCastRefresh : BroadcastChannel ;
  
  @Output() StateRefreshEvent = new EventEmitter<Boolean>() ;
  url;
  recipientId = "";
  done = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer) {
      this.broadCastRefresh = new BroadcastChannel('RefreshCorses') ;
      
    }  
  stateRefresh : Boolean = false ;
 
  ngOnInit() {
  
     this.url = this.sanitizer.bypassSecurityTrustResourceUrl(localStorage.getItem("link"));
    }
    Refresh(){
      this.stateRefresh = true ;
      // mqybe insert a timeout // 
     this.broadCastRefresh.postMessage({
        type: 'boolean',
        stateRefresh: this.stateRefresh
        });
    } 
   
   
  }
