import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from '../data.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent {

  constructor(
    private data : DataService,
    private translate : TranslateService,
    public dialogRef: MatDialogRef<PasswordResetComponent>
  ) { }

  passwordReset(email){
    this.dialogRef.close();
    this.data.passwordReset(email).subscribe((data) => console.log(data));
  }
}
