import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-percent-chart',
  templateUrl: './percent-chart.component.html',
  styleUrls: ['./percent-chart.component.scss']
})
export class PercentChartComponent {

  percent = {
    showScore: 0
  };
  @Input() tabs = new Input();
  @Input() name = new Input();

  constructor() { }
}
