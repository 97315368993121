import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { DataService } from '../data.service';

@Component({
  selector: 'app-dialog-content-add-user-to-teams',
  templateUrl: './dialog-content-add-user-to-teams.component.html',
  styleUrls: ['./dialog-content-add-user-to-teams.component.scss']
})
export class DialogContentAddUserToTeamsComponent implements OnInit {

  userChecked: any
  teamList: any

  constructor(
    private dataService: DataService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.userChecked = data.userChecked,
    this.teamList = data.teamList.filter(team => team.teamId !== -1)
  }

  ngOnInit() {
    this.teamList.forEach(
      team => {
        team.selected = false
      }
    )
  }
  
  attributeUsers() {
    this.teamList.forEach(
      team => {
        if(team.selected) {
          this.userChecked.forEach(
            user => {
              // let formdata = {
              //   uid: JSON.parse(localStorage.getItem('user')).idUser,
              //   teamId: team.teamId,
              //   userToAddId: user.UserId
              // }
              this.dataService.addUserInTeam(JSON.parse(localStorage.getItem('user')).idUser, team.teamId, user.UserId).subscribe(
                data => console.log(data)
                
              )
          });
        }
      }
    )
  }

}