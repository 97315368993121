import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import { DataService } from "../data.service";
import { Chart } from "chart.js";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { forkJoin, Subject } from "rxjs";
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit /*, AfterViewInit*/ {
  charts = [];
  widgets = [];
  subject = new Subject<boolean>();

  names = ["dashboard.mean-time", "dashboard.students-per-session", "dashboard.students-per-course"]
  percentChartNames = ["dashboard.average-score-per-parcour", "dashboard.average-score-per-session"]
  
  showExport = false;

  public coursesOverview = [];
  public sessions =[] ;
  public parcours =[] ;
  public SessionScore = 0  ;
  public SessionList = [] ; // get quickly all sessions//
  public CourseList = [] ; // get quickly all courses //
  private errorsCounts = {
    "courses": 0,
    "sessions": 0,
  }
  private CurrentUserId = 1 ;
  private _isCoursesFull = false;
  private _isSessionsFull = false;

  public formatedDashboard = []
  public formatedTabs = []
  public formatedCompletion =
    {
      name: "dashboard.average-completion-per-session",
      tabs: []
    }

  constructor(
    private dataService: DataService,
    private cdRef: ChangeDetectorRef,
    private translate: TranslateService,
    private router: Router
  ) {}

  ngOnInit() {
   
    this.CurrentUserId = parseInt (JSON.parse(localStorage.getItem("user"))["idUser"]) ;
    //this.widgets = this.divideCharts(4);
    let observable = [] 
    this.SessionList = [] ;
    this.CourseList=[] ;
    observable[0] = this.dataService.getDashboardData(this.CurrentUserId) 
    forkJoin(observable).subscribe(result=>{
   
        result[0].subscribe(data=>{
        this.SessionList =  data["message"]["sessions"]
        this.CourseList = data["message"]["courses"]
         this.getAllSessionsStats() ;
         this.getAllCoursesStats() ;
        })
    })
    // this.widgets = this.divideCharts(4);
  }
    /*
  ngAfterViewInit() {
  
    this.dataService
      .getDashboardData(JSON.parse(localStorage.getItem("user"))["idUser"])
      .subscribe((data) => {
        console.log(data);
        let message = data["message"] ;
        console.log(message["sessions"]);
        this.charts.push(data["message"]);
      });
     
  }
  
/*
  drop(event: CdkDragDrop<{}[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  divideCharts(sections: number) {
    const result = [];
    for (let j = 0; j < sections; ++j) {
      result.push([]);
    } //we create it, then we'll fill it

    const wordsPerLine = Math.ceil(this.widgets.length / 3);

    for (let line = 0; line < sections; ++line) {
      for (let i = 0; i < wordsPerLine; ++i) {
        const value = this.widgets[i + line * wordsPerLine];
        if (!value) continue;
        result[line].push(value);
      }
    }
    return result;
  }

  addEntry() {
    const entry = {
      sessions: [
        {
          id: "3877",
          type: "session",
          name: "New entry added for D&D",
          numberUsers: 40,
          meanTime: 0,
          meanScore: 0,
          finished: 0,
          started: 3,
          ignored: 98,
        },
      ],
      courses: [
        {
          id: "50",
          type: "course",
          name: "New entry added for D&D",
          numberUsers: 40,
          completion: 0,
          meanScore: 0,
        },
      ],
    };
    let added = false;
    this.widgets.forEach((arr) => {
      if (
        (arr.length === 0 ||
          this.widgets.indexOf(arr) === this.widgets.length - 1 ||
          arr.length < this.widgets[this.widgets.indexOf(arr) + 1]) &&
        added !== true
      ) {
        arr.push(entry);
        added = true;
      }
    });
    console.log(this.widgets);
  }

  initializeCharts() {
    let ctxs = document.getElementsByTagName("canvas");
    for (let i = 0; i < ctxs.length; i = i + 2) {
      let doughnutChart = new Chart(ctxs.item(i), {
        type: "doughnut",
        data: {
          labels: ["Score moyen par session", "Score moyen"],
          datasets: [
            {
              label: "Session",
              data: [
                this.charts[i]["sessions"][0]["meanScore"],
                0,
                100 - this.charts[i]["sessions"][0]["meanScore"],
              ],
              backgroundColor: [
                "rgba(255, 99, 132, 0.5)",
                "rgba(255, 255, 255, 0)",
                "rgba(255, 255, 255, 1)",
              ],
              borderColor: [
                "rgba(255,99,132,1)",
                "rgba(255, 255, 255, 0)",
                "rgba(255, 255, 255, 1)",
              ],
              borderWidth: 1,
            },
            {
              label: "Parcours",
              data: [
                0,
                this.charts[i]["courses"][0]["meanScore"],
                100 - this.charts[i]["courses"][0]["meanScore"],
              ],
              backgroundColor: [
                "rgba(255, 255, 255, 0)",
                "rgb(236, 151, 24)",
                "rgba(255, 255, 255, 1)",
              ],
              borderColor: [
                "rgba(255, 255, 255, 0)",
                "rgba(255,99,132,1)",
                "rgba(255, 255, 255, 1)",
              ],
              borderWidth: 1,
            },
          ],
        },
        options: {
          //cutoutPercentage: 40,
          responsive: true,
        },
      });

      let barChart = new Chart(ctxs.item(i + 1), {
        type: "bar",
        data: {
          datasets: [
            {
              barPercentage: 0.5,
              barThickness: 6,
              maxBarThickness: 8,
              minBarLength: 2,
              data: [
                this.charts[i]["sessions"][0]["ignored"],
                this.charts[i]["sessions"][0]["started"],
                this.charts[i]["sessions"][0]["finished"],
              ],
            },
          ],
        },
      });
    }
  }
  */
 async getAllSessionsStats()
 {
  this.SessionList.forEach(async (elt,index) => {
    await setTimeout(() => this.getSessionStatiscs(index), index * 5)
  });
 }
 async getAllCoursesStats()
 {
  this.CourseList.forEach(async (elt,index) => {
    await setTimeout(() => this.getCoursesStatiscs(index), index * 5);
  });
 }
  async getSessionStatiscs(sessionNumber : number)
  {
   // session number is the number of session on the returned array it's different of session id 
    // get more information about specific session  
    this.dataService.getSessionInformationOnDashboard(this.CurrentUserId.toString(), sessionNumber.toString()).subscribe(
      data=>{
        if (data["message"])
        this.sessions.push(data["message"]["sessions"])
      },
      error => {
        this.errorsCounts.sessions++
      }
    )
    
  }
 async getCoursesStatiscs(courseNumber : number)
 {
  this.dataService.getCourseInformationOnDashboard(this.CurrentUserId.toString(), courseNumber.toString()).subscribe(
    data=>{
      if (data["message"])
      this.parcours.push(data["message"]["courses"])
    },
    error => {
      this.errorsCounts.courses++
    }
  )
 }

//  isSessionsFull = () => this._isSessionsFull;

//  isCoursesFull = () => this._isCoursesFull;

 isSessionsFull() {
  return this.sessions.length + this.errorsCounts.sessions === this.SessionList.length && this.sessions.length > 0;
 }

 isCoursesFull() {
  return this.parcours.length + this.errorsCounts.courses === this.CourseList.length && this.parcours.length > 0;
 }

 formatDashboard() {
  /* FORMAT SESSIONS TO SERIES !!! */
  if (!this.isSessionsFull() || !this.isCoursesFull()) return false;
  if (this.formatedDashboard.length === 0) {
    console.log("sessions", JSON.stringify(this.sessions))
    this.names.forEach(
      (name, index) => {
        this.formatedDashboard.push([
            {
              name,
              series: [],
              index
            }
        ])
        switch (index) {
          case 0:
            this.sessions.forEach(
              data => {
                this.formatedDashboard[index][0].series.push({
                  name: data[0].name,
                  value: data[0].meanTime
                })
              }
            )
            break;
          case 1:
            this.sessions.forEach(
              data => {
                this.formatedDashboard[index][0].series.push({
                  name: data[0].name,
                  value: data[0].numberUsers
                })
              }
            )
            break;
          case 2: 
            this.parcours.forEach(
              data => {
                this.formatedDashboard[index][0].series.push({
                  name: data[0].name,
                  value: data[0].numberUsers
                })
              }
            )
            break;
        }
        // switch (index) {
        //   case 0:
        //     this.series[0][0].series.forEach(
        //       serie => {
        //         serie[0].value = serie[0].meanTime
        //       }
        //     )
        //     break;
        // }
      }
    )
  }
  return true;

 }

 formatTabs() {   
  if (!this.isSessionsFull() || !this.isCoursesFull()) return false;
  if (this.formatedTabs.length === 0) {
    this.percentChartNames.forEach(
      (name, index) => {
        this.formatedTabs.push({
          name,
          tabs: []
        })
        switch (index) {
          case 0:
            this.formatedTabs[index].tabs.push(
              {
                name: "Parcours",
                radioGroup: []
              }
            )
            this.parcours.forEach(
              course => {
                let courseToPush = {
                  name: course[0].name,
                  showScore: course[0].completion
                }
                this.formatedTabs[index].tabs[0].radioGroup.push(courseToPush)
              }
            )
            break;
          case 1:
            this.formatedTabs[index].tabs.push(
              {
                name: "Session",
                radioGroup: []
              },
              {
                name: "Parcours",
                radioGroup: []
              }
            )
            this.sessions.forEach(
              session => {
                let sessionToPush = {
                  name: session[0].name,
                  showScore: session[0].meanScore
                }
                this.formatedTabs[index].tabs[0].radioGroup.push(sessionToPush)
              }
            )
            this.parcours.forEach(
              course => {
                let courseToPush = {
                  name: course[0].name,
                  showScore: course[0].meanScore
                }
                this.formatedTabs[index].tabs[1].radioGroup.push(courseToPush)
              }
            )
            break;
        }
      }
    )
  }
  return true;
 }

 formatCompletion() {
  if (!this.isSessionsFull()) return false;
  if (this.formatedCompletion.tabs.length === 0) {
    this.sessions.forEach(
      session => {
        this.formatedCompletion.tabs.push(
          {
            name: session[0].name,
            started: session[0].started,
            finished: session[0].finished,
            ignored: session[0].ignored
          }
        )
      }
    )
  }
  return true;
 }

 navigateExport() {
   this.showExport = true
 }

 exportClosed(event) {
   this.showExport = false
 }
}
