import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-chart-card',
  templateUrl: './chart-card.component.html',
  styleUrls: ['./chart-card.component.scss']
})
export class ChartCardComponent implements OnInit, AfterViewInit {

  @Input() chart;
  @Output() finished = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {

    this.finished.emit("done")
  }

}
