import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import memo from 'memo-decorator';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-cluster-vertical-bar',
  templateUrl: './cluster-vertical-bar.component.html',
  styleUrls: ['./cluster-vertical-bar.component.scss']
})
export class ClusterVerticalBarComponent implements OnInit {

  @Input() data = new Input();
  @Input() name = new Input();

  view: any[] = [window.innerWidth * 0.2, 400];

  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = false;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = "";
  showYAxisLabel: boolean = false;
  showGridLines: boolean = true;
  showDataLabel: boolean = false;
  animations: boolean = true;
  yAxisLabel: string = '';
  legendTitle: string = 'Sessions';
  yScaleMax: number = 100;

  update$: Subject<boolean> = new Subject();

  data_displayer : any;  

  // colorScheme = {
  //   domain: ['#5AA454', '#C7B42C', '#AAAAAA', '#123456', '#654321']
  // };
  schemeType: string = 'linear';

  constructor(private translate: TranslateService) { }

  ngOnInit() {
    console.log(this.data)
    
    this.translate.get(this.name).subscribe(
      value => {
        this.xAxisLabel = value
      }
    );

    this.data.forEach(
      (chart, index) => {
        chart.series.forEach((serie, index) => {
          serie.index = index;
          serie.selected = true;
          serie.color = this.generateRandomColor();
          // serie.value = Math.floor(Math.random() * 100);
        });
    
        // chart.index = index;
      }
    )

    this.data_displayer = JSON.parse(JSON.stringify(this.data));
   
  }

  popItem(item) {
    this.data_displayer.forEach(element => {
      let indexToRemove = element.series.findIndex(
        serie => serie.index === item.index && serie.name === item.name
      )
      if (indexToRemove !== -1)
        element.series.splice(indexToRemove, 1);
    });
    this.update();

  } 

  pushItem(item) {
    console.log(item)
    this.data_displayer.forEach((element, index) => {
      let indexFound = element.series.findIndex(
        serie => serie.index === item.index && serie.name === item.name
      )
      if (indexFound === -1)
        element.series.splice(item.index, 0, this.data[index].series[item.index]);
    });

    this.update();

  } 

  @memo()
  memoColor(name) {
    return this.getColor(name)
  }

  getColor(name) {
    let result: any[] = [];
    this.data_displayer[0].series.forEach(
      serie => {
        result.push({"name": serie.name,"value": serie.color});
      }
    )
    return result;
  }

  generateRandomColor() {
    var letters = '23456789ABCD';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 12)];
    }
    return color;
  }

  update() {

    this.data_displayer = JSON.parse(JSON.stringify(this.data_displayer));

    console.log(this.data_displayer);

  }

  lookForItem(item, i) {
    
    for (let item_in_data in this.data_displayer[i].series) {

      if (this.data_displayer[i].series[item_in_data].index == item.index
        && this.data_displayer[i].series[item_in_data].name == item.name) {

        return item_in_data;

      }

    }

  }

}
