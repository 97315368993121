import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { SearchService } from '../search.service';
import { DataService } from '../data.service';
import { Router } from '@angular/router';
import { MultiWindowService, Message } from 'ngx-multi-window';

@Component({
  selector: 'app-parcours',
  templateUrl: './parcours.component.html',
  styleUrls: ['./parcours.component.scss']
})



export class ParcoursComponent implements OnInit {
  
  @Input() cards;
  @Input() notifications;
  searchText;
  filteredCards = [];
  stateRefresh : Boolean = false ;
  dataUpdated = new EventEmitter<boolean>();
  broadCastRefresh : BroadcastChannel ;
  mode: ProgressSpinnerMode = "determinate";
  color: ThemePalette = "primary";
  color2: ThemePalette = "warn";
  public user ;
  constructor(private searchService: SearchService,
    private data: DataService,
    private router: Router,
    private multiWindowService: MultiWindowService) {
     
      this.broadCastRefresh = new BroadcastChannel('RefreshCorses');
      this.broadCastRefresh.onmessage= (message)=>{
        console.log('get messa'+message)
        this.initialize() ;
      }
     
    }

  ngOnInit() {
    this.multiWindowService.onMessage().subscribe((value: Message) => {
      console.log('Received a message from ' + value.senderId + ': ' + value.data);
      this.multiWindowService.clear()
      this.initialize();
    });
    this.initialize();
  }

  begin(courseUrl: string) {
    localStorage.setItem("link",courseUrl);
    window.open( "/StartCourse", 'blank' );
  }
  
  isTest(card) {
    return card.titre.match(/.+T-.+/gm) || card.titre.match(/.+T0-.+/gm);
  }

  getTimeImage(score) {
    if (score === 100)
      return "../../assets/images/icon_time_blue.png"
    else if (score < 100 && score > 0)
      return "../../assets/images/icon_time_yellow.png"
    else if (score === 0)
      return "../../assets/images/icon_time.png"
  }

  courseClosed(event) {
    console.debug(event)
    this.ngOnInit();
  }

  initialize() {
    this.cards = []
    this.filteredCards = []
     this.user = JSON.parse(localStorage.getItem("user"));
    let coursesGetted = true;
    if (coursesGetted) {
      coursesGetted = false;
      // this.data.getCourses(this.user.idUser).subscribe(
      //   data => {
          // this.inProgressCourses = this.finishedCourses = 0
      this.user["subscriptions"].forEach(
        sub => {
          console.log("Sub Date", new Date(sub["endDate"]))
          console.log("Actual Date", new Date())
          if (new Date(sub["endDate"]) > new Date())
          this.data.getSubscription(sub["id"]).subscribe(
            subMod => {
              this.data.getModule(subMod["modules"][0]['id']).subscribe(
                (module: any) => {
                  let found = this.cards.find(mod => mod.id === module.id)
                  if (found && new Date(found.updated_at) < new Date(module.updated_at)) {
                    this.cards = this.cards.filter(mod => mod.id !== found.id)
                  }
                  this.cards.push(module)
                  this.dataUpdated.emit(true);
                }
              )
            }
          )
        }
      )
      this.dataUpdated.subscribe(
        ev => {
          if (this.cards.length > 1) {
            let title = ""
            let path = ""
            let tmp = ""
            let alreadySet = false
            this.cards.forEach((module, index) => {
              if (!module.id || alreadySet) {
                alreadySet = true
              } else {
                title += module.title + (index !== (this.cards.length - 1) ? ' & ' : '')
                let res = [...module.path.matchAll(/(.+sources\/)(.+)(\/index\.html)/gm)]
                if (res[0] && res[0].length === 4) {
                  if (!path) path = res[0][1]
                  res[0][2] === "minf" ? tmp = res[0][2] : path += res[0][2]
                  if (index === this.cards.length - 1) {
                    if (tmp) path += tmp
                    path += res[0][3]
                  }
                }
              }

            });
            if (!alreadySet) {
              this.cards = [{
                title,
                path
              }]
            } else {
              this.cards = [this.cards[0]]
            }
            this.filteredCards = this.cards
          }
        }
      )
          // this.cards.pop()
          // this.cards.pop()
          // this.cards = this.cards.filter(card => {
          //   if (card.cmi_score_max === 50) {
          //     card.status = "progressing";
          //     // this.inProgressCourses++;
          //   } else if (card.cmi_score_max == "") {
          //     card.status = "todo";
          //     card.cmi_score_max = 0;
          //   } else {
          //     card.status = "finished";
          //     // this.finishedCourses++;
          //   }
          //   if (card.total_time === "") {
          //     card.total_time = "0"
          //   }
          //   if (card.date_dispatch !== "" && card.lang === "French") {
          //     return card;
          //   }
          // });
          // this.cards = this.cards.sort((a, b) => {
          //   let dateA = new Date(a.date_dispatch);
          //   let dateB = new Date(b.date_dispatch);
          //   if (dateA < dateB)
          //     return 1;
          //   if (dateA > dateB)
          //     return -1;
          //   return 0;
          // })
          // // if (this.finishedCourses === this.cards.length - 1 && this.cards.length > 1) {
          // //   this.finalText = "Vous avez terminé tout les parcours !";
          // // }
          // // else if (this.finishedCourses > 0) {
          // //   this.finalText = "Continuez comme ça !";
          // // } else if (this.finishedCourses === 0) {
          // //   this.finalText = "Réalisez votre premier parcours !";
          // // } 
          // // this.barValue = this.finishedCourses !== 0 ? this.finishedCourses * 100 / this.cards.length : 0
      this.filteredCards = this.cards;
      coursesGetted = true;
    }
    //   )
    // }
    console.log('Filtered Cards: ', this.filteredCards)
    console.log('Cards: ', this.cards)
    this.searchService.text.subscribe(
      text => {
        this.searchText = text;
        this.filteredCards = this.cards.filter((card) => {
          return card.titre.toLowerCase().includes(this.searchText);
        });
        console.log(this.cards)
      }
    )
  }
  ReceiveState($event)
  {
    if ($event)
    {
      this.initialize() ;
    }
  }
  ShowResults(id)
  {
    this.router.navigate(['/result-user'], {queryParams:{current_user:this.user.idUser, courseId: id}})
   // this.router.navigate(['/result-user'])

  }

  getTypeOfCard(title: string) {
    // 0 : Tronc
    // 1 : Membre Inf
    // 2 : Both
    return title.match(/.*Inf.*/gm) ? (title.match(/.*Tron.*/gm) ? 2 : 1) : 0
  }
}
