import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { DataService } from '../data.service';

@Component({
  selector: 'dialog-content-import-scorm',
  templateUrl: './dialog-content-import-scorm.html',
  styleUrls: ['./dialog-content-import-scorm.scss']
})
export class DialogContentImportScorm {
  file: any;
  moduleId = "";

  constructor(
    private dataService: DataService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.moduleId = data.id;
  }

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  prepareFilesList(file: any) {
    if (file[0].size < 90000000 && file[0].type === "application/zip") {
      this.file = file[0];
      console.log(this.file)
    }
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 o';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['o', 'Ko', 'Mo'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  prepareModule() {
    this.moduleId === "" ? this.createModule() : this.updateModule();
  }

  createModule() {
    this.dataService.createModule(JSON.parse(localStorage.getItem('user')).idUser, this.file).subscribe(
      data => {
        console.log(data)
      }
    )
  }

  updateModule() {
    this.dataService.updateModule(JSON.parse(localStorage.getItem('user')).idUser, this.moduleId, this.file).subscribe(
      data => {
        console.log(data)
      }
    )
  }
}