import { Component, OnInit, Input } from '@angular/core';
import { DataService } from '../data.service';
import { Validators, FormControl } from '@angular/forms';
import { of } from 'rxjs';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  @Input() notifications;
  @Input() loadedEvent;

  constructor(private data: DataService) { }

  // notifications = ["first notification text", "second notification text", "this is a pretty long notification text"];

  private user;
  public currentNotif = [];
  public isLoading = false;

  mode: ProgressSpinnerMode = "indeterminate";
  color: ThemePalette = "primary";

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem("user"));
  }

  changeNotif(id, index) {
    if (this.currentNotif["m_id"] === id) {
      this.currentNotif = [];
    } else {
      this.currentNotif = this.notifications.find(
        (notif) => notif.m_id === id
      )
    }
    // let notificationsCards = document.getElementsByClassName('notification-cards');
    // Array.prototype.forEach.call(notificationsCards, element => {
    //   element.classList.remove("selected");
    // });
    // notificationsCards.item(index).classList.add("selected");
  }

  getErrorMessage() {
    return '';
  }

  isSelected(id) {
    return id === this.currentNotif["m_id"];
  }

  sendMessage() {
    if (!this.currentNotif) {
      this.currentNotif = this.notifications[this.notifications.length - 1];
    }
    let message: any = document.getElementById("text");
    if (message.value) {
      this.data.sendMessage(this.user.idUser, this.currentNotif["m_id"], message.value).subscribe()
      for(var i = this.notifications.length - 1; i >= 0; i--) {
        if(this.notifications[i]["m_id"] === this.currentNotif["m_id"]) {
          this.notifications.splice(i, 1);
        }
      }
      this.currentNotif = [];
    }
  }

  sendDefaultMessage() {
    if (!this.currentNotif) {
      this.currentNotif = this.notifications[this.notifications.length - 1];
    }
    this.data.sendMessage(this.user.idUser, this.currentNotif["m_id"], "OK").subscribe()
    for(var i = this.notifications.length - 1; i >= 0; i--) {
      if(this.notifications[i]["m_id"] === this.currentNotif["m_id"]) {
        this.notifications.splice(i, 1);
      }
    }
    this.currentNotif = [];
  }

  removeSelectedNotif() {
    this.currentNotif = [];
  }

  isLoaded = () => this.loadedEvent;

  isNotificationSelected = () => this.currentNotif.length !== 0;

  isMessage = () => this.currentNotif["m_Answer"] === "TRUE";
}
