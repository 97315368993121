import { Component, Input, OnChanges, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-list-data-models',
  templateUrl: './list-data-models.component.html',
  styleUrls: ['./list-data-models.component.scss']
})
export class ListDataModelsComponent implements OnInit, OnChanges {

  @Input() data = new Input();
  @Input() dataIndex = new Input();
  @Output() item_pop = new EventEmitter();
  @Output() item_push = new EventEmitter();

  constructor() { }

  ngOnInit() {

    console.log(this.data)

  }

  ngOnChanges() {
    // console.log('changes')
  }

  isSelectedItem(item : any) : void {
    if (item.selected === false) {
      this.item_push.emit(item);
    } else if (item.selected === true) {
      this.item_pop.emit(item);
    }
  }

  trackFn(index, item) {
    // console.log(index, item)
    if (!item) return null;
    return index;
  }

}
