import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxPaginationModule } from 'ngx-pagination';
import { exit } from 'process';
import { NavigationExtras, Router } from '@angular/router';
import { MatPaginatedTabHeader } from '@angular/material/tabs/typings/paginated-tab-header';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DialogContentAddUser } from '../dialog-content-add-user/dialog-content-add-user';
import { DialogContentAddUserToTeamsComponent } from '../dialog-content-add-user-to-teams/dialog-content-add-user-to-teams.component';

export interface export_user_form {
  gender: String,
  surname: String,
  name: String,
  email: String,
  phone_number?: String,
  language?: String,
  role?: String,
  company?: String,
  job?: String;
  registration_number?: String,
  team?: String,
  send_notification?: boolean,
  sessions?: [],
  pass?: String,
  limite: String;
  uid: any;
  updatedUserId?: any,
}

export interface User {
  email: string;
  id: string;
  name: string;
  teamName: string;
  validityDate: string;
  selected: boolean
}

export interface result_user {
  id: number,
  courses: any,
  name: string,
  validity_date: string,

}

@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss'],
})

export class TeamsComponent implements OnInit, AfterViewChecked {

  addPrint = false;
  teams = [];
  displayedTeamId : any;
  deletedUserIdCache : any = 0;
  charts = [];
  value = 10;
  user = undefined;
  name = undefined
  see = undefined;
  send = undefined;
  hidden = false;
  team: User[] = []
  email = undefined;
  constructor(private data: DataService,
    private http: HttpClient,
    private router: Router,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog) { }
  myControl = new FormControl();
  filteredOptions: Observable<User[]>;

  form_user : export_user_form = {
    gender: "male",
    surname: "",
    name: "",
    email: "",
    phone_number: "",
    language: "French",
    role: "User",
    company: "",
    pass: "",
    registration_number: "",
    team: "No team",
    send_notification: false,
    sessions: [],
    limite: null,
    uid: 0,
    updatedUserId: 0,
  }

  usersToRemove = []
  allChecked = false

  tab_of_user_info = ["surname", "name", "email", "password", "phone_number", "gender", "job", "registration_number", "limite"]

  display_sessions: boolean = false;
  sessions: any = [];
  sessions_filter_string: string = '';

  display_mode: number = 1; //1 --> show teams ** 2 --> create user ** 3 --> create many users ** 4 --> looking user specs ** 5 --> display courses for users **6 --> display modules of the module
  edition_mode: string = ''; // either create or update

  result_user_courses: result_user;
  result_user_modules: any;
  result_user_module_selected: any;
  file_url: any;

  config = {
    itemsPerPage: this.value,
    currentPage: 1,
    totalItems: 0
  }
  
  onChange(value){
    this.config.itemsPerPage = +value;
    this.config.currentPage = 1;
  }

  pageChanged(event){
    this.config.currentPage = event;
    console.log(this.config);
  }

  showEdit(nameUse) {
    this.displayedTeamId = nameUse.teamId
    this.data.getUserByTeam(this.user.idUser, nameUse.teamId).subscribe(result => {
      this.hidden = true;
      result["users"].forEach(user => {
        let endDate = user.validityDate !== undefined ? user.validityDate : user.availabilityDate
        user.expirationDate = this.dateDiffInDays(new Date(Date.now()), new Date(endDate));
        user.expirationPercent = Math.trunc((user.expirationDate / this.dateDiffInDays(new Date(user.startDate), new Date(endDate))) * 100);
        user.expirationDate = user.expirationDate >= 0 ? user.expirationDate : 0;
        user.expirationPercent = user.expirationPercent >= 0 ? user.expirationPercent : 100;
        user.selected = false
      });
      this.team = result["users"];
      this.config.totalItems = this.team.length;
      this.myControl.setValue('');
    });
  }

  sendMail(nameUse) {
    this.hidden = true;
    this.email = nameUse
  }

  displayFn(user: User): string {
    return user && user.name ? user.name : '';
  }

  private _filter(name: string): User[] {
    const filterValue = name.toLowerCase();
    return this.team.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  ngOnInit() {

    this.downloadCSVFile();

    this.user = JSON.parse(localStorage.getItem("user"));
    this.form_user.uid = this.user.idUser;
    this.data.getAllUsers(this.user.idUser).subscribe(
      data => {
        this.teams.unshift(data["teams"]);
      }
    )
    this.data.getTeam(this.user.idUser).subscribe(
      data => {
        data["teams"].forEach(element => {
          this.teams.push(element);
        });
      })

    let See = document.getElementById("see");
    let Users = document.getElementById("Users");
    if (See) {
      See.addEventListener("click", () => {
        if (getComputedStyle(Users).display != "none") {
          Users.style.display = "none";
        } else {
          Users.style.display = "block";
        }
      })

    }

    let Send = document.getElementById("send");
    let Mails = document.getElementById("Mails");
    if (See) {
      See.addEventListener("click", () => {
        if (getComputedStyle(Mails).display != "none") {
          Mails.style.display = "none";
        } else {
          Mails.style.display = "block";
        }
      })
    }

    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filter(name) : this.team.slice())
      );

    this.data.getSessions(this.user.idUser).subscribe(
      data => {

        for (let elem_in_sessions in data) {

          if (data[parseInt(elem_in_sessions)] !== undefined) {

            data[parseInt(elem_in_sessions)].selected = false;
            this.sessions.push(data[parseInt(elem_in_sessions)]);
          }

        }
        this.display_sessions = true;

      }
    )

  }

  ngAfterViewChecked(): void {
    // Change pagination texts to french
    // let previousBtn = document.getElementsByClassName('pagination-previous')
    // for (let i = 0; i < previousBtn.length; ++i) {
    //   previousBtn.item(i).lastChild.textContent = "Précédent"
    // }
    // let nextBtn = document.getElementsByClassName('pagination-next')
    // for (let i = 0; i < nextBtn.length; ++i) {
    //   nextBtn.item(i).lastChild.textContent = "Suivant"
    // }
  }

  dateDiffInDays(a, b) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;

    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }

  addUser(): void {
    return
  }

  checkMissingInformations() {
    if (this.form_user.name !== "") {
      //console.log("surname missing")
      if (this.form_user.surname !== "") {
        //console.log("email missing")
        if (this.form_user.email !== "") {
          //console.log("company missing")
          if (this.form_user.company !== "") {
            return true
          }
        }
      }
    }
    //console.log("missiing informations")
    return false
  }

  updateUser(): void {
    if (this.checkMissingInformations()) {
      console.log(this.form_user)
      this.data.updateUser(this.form_user).subscribe(
        data => {
          console.log(data)
        }
      );
    }
  }

  createUser(elem?: export_user_form): void {
    if (this.checkMissingInformations()) {
      this.data.createUser((elem !== undefined) ? this.form_user : elem).subscribe(
        data => {
          //  console.log(data)
        });
    }
  }

  addTeam(){
    if(this.addPrint){
      var newTeam = (<HTMLInputElement>document.getElementById("AddTeam")).value;
      this.teams.push({teamId : this.teams.length+1 ,name : newTeam, numberUsers: 0, parentId: false, subteams: Array(0), users : Array(0)});
      this.addPrint = false;
      this.data.createTeam(this.user.idUser, newTeam).subscribe( result => {
        console.log(result);
      });
    } else {
      this.addPrint = true;
    }
  }

  removeUser(user): void {
    let idUser = user.id
    this.data.removeUser(idUser).subscribe(
      data => {
        console.log(data)
      }
    );
    
    this.teams.forEach(team => {
      if(team.teamId == this.displayedTeamId && this.deletedUserIdCache != idUser){
        team.numberUsers--
      }
    })
    this.data.getUserByTeam(this.user.idUser, this.displayedTeamId).subscribe(
      result => {
        result["users"].forEach(user => {
          let endDate = user.validityDate !== undefined ? user.validityDate : user.availabilityDate
          user.expirationDate = this.dateDiffInDays(new Date(Date.now()), new Date(endDate));
          user.expirationPercent = Math.trunc((user.expirationDate / this.dateDiffInDays(new Date(user.startDate), new Date(endDate))) * 100);
          user.expirationDate = user.expirationDate >= 0 ? user.expirationDate : 0;
          user.expirationPercent = user.expirationPercent >= 0 ? user.expirationPercent : 100;
          user.selected = false
        });
        this.team = result["users"];
        this.config.totalItems = this.team.length;
        this.myControl.setValue(this.team);
      }
    );
    this.deletedUserIdCache = idUser;
  }

removeTeam(indexTeam) {
  var idTeam = this.teams[indexTeam].teamId;
  this.data.deleteTeam(this.user.idUser, idTeam).subscribe(result => {
    console.log(result);
    this.teams.splice(indexTeam, 1);
  });
}

  updateTeam() {

  }
  addSession(id: never): void {
    if (this.form_user.sessions.includes(id)) {
      this.form_user.sessions.slice(this.form_user.sessions.indexOf(id) + 1, 1);
    }
    else {
      this.form_user.sessions.push(id);
    }
  }

  showAddUser() {

  }
  downloadCSVFile() {

    var blob = new Blob(['Pour l\'importation, veuillez remplacer les deux exemples ci-dessous en veillant à ne pas supprimer les quatres premières lignes;;;;;;;;\n"La derniere colonne (limite) est obligatoire et doit avoir pour chaque ligne d\'utilisateur la valeur ""NULL""";;;;;;;;\nLes colonnes avec une astérisque sont obligatoires;;;;;;;;\nNom*;Prenom*;Mail / Login*;Mot de passe;Telephone;Sexe* (female / male);Poste;Matricule;limite*\nDoe;John;john.doe@example.fr;123;06.00.00.00.00;male;Commercial;253004;NULL\nSmith;Jane;jane.smith@example.fr;123;06.00.00.00.00;female;Commercial;;NULL\n'], { type: "application/csv".toString() });
    this.file_url = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
  }

  onFileDropped($event): void {
    let csv_user: any = $event.target.files[0];
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      let result: Array<String> = String(fileReader.result).split('\n');
      let tab_parsed: Array<export_user_form> = this.parseCsvToJSON(result);
      for (let elem of tab_parsed) {
        this.createUser(elem);
      }


    }
    fileReader.readAsText(csv_user);

  }

  parseCsvToJSON(csv): export_user_form[] {

    let tab_of_new_users: Array<export_user_form> = [];

    for (let new_user: number = 4; new_user < csv.length - 1; new_user++) {
      let user_data: Array<String> = csv[new_user].split(";");

      let user: export_user_form = {
        surname: user_data[0],
        name: user_data[1],
        email: user_data[2],
        pass: user_data[3],
        phone_number: user_data[4],
        gender: user_data[5],
        job: user_data[6],
        registration_number: user_data[7],
        limite: user_data[8],
        uid: this.user.id,
      }


      tab_of_new_users.push(user)
      // console.log(tab_of_new_users);
    }

    return tab_of_new_users;
  }

  test($event) {
    // console.log($event);
  }

  displayUserInfo(user: any): void {
    this.router.navigate(['/result-user'], {queryParams:{current_user:user.id, firstSelected: true}})
  }
  loadUser(user: any): void {

    console.log(user);
    for (const [key, values] of Object.entries(user)) {
      switch (key) {
        case "UserId":
          this.form_user.updatedUserId = values;
          break;
        case "name":
          let tab_name_surname = String(values).split(" ");
          this.form_user.name = tab_name_surname[0];
          if (tab_name_surname[1]) this.form_user.surname = tab_name_surname[1];
          break;
        case "email":
          this.form_user.email = String(values);
          break;
        case "teamName":
          this.form_user.team = String(values);
          break;
      }
    }
    // this.display_mode = 2;
    this.edition_mode = 'update';
    this.alertAddUser()

  }

  resetUserForm(): void {

    console.log('rester user form')

    this.display_mode = 1;

    this.form_user = {
      gender: "male",
      surname: "",
      name: "",
      email: "",
      phone_number: "",
      language: "French",
      role: "User",
      company: "",
      pass: "",
      registration_number: "",
      team: "No team",
      send_notification: false,
      sessions: [],
      limite: null,
      uid: 0,
    }
  }

  updateView() {
    // this.display_mode = 2
    this.edition_mode = ''
    console.log(this.display_mode);
    this.alertAddUser()
  }

  alertAddUser() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      form_user: this.form_user,
      sessions: this.sessions,
      edition_mode: this.edition_mode,
      teams: this.teams
    }

    const dialogRef = this.dialog.open(DialogContentAddUser, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });

  }

  alertAddUserToTeams() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      userChecked: this.usersToRemove,
      teamList: this.teams
    }

    const dialogRef = this.dialog.open(DialogContentAddUserToTeamsComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });

  }

  removeAll() {
    if (!this.allChecked) {
      this.usersToRemove = this.team
      this.team.forEach(
        user => {
          user.selected = true;
        }
      )
    } else {
      this.usersToRemove = []
      this.team.forEach(
        user => {
          user.selected = false;
        }
      )
    }
    
  }

  removeOne(user) {
    if (!user.selected) {
      this.usersToRemove.push(user)
    } else {
      this.usersToRemove = this.usersToRemove.filter(
        userToRemove => userToRemove.UserId !== user.UserId
      )
    }
  }
  deleteCheckedUsers() {
    this.removeUsersFromTeam()
    this.usersToRemove = []
  }

  progressBarColor(color1, color2, weight) {
    var w1 = weight;
    var w2 = 1 - w1;
    var rgb = [Math.round(color1[0] * w1 + color2[0] * w2),
        Math.round(color1[1] * w1 + color2[1] * w2),
        Math.round(color1[2] * w1 + color2[2] * w2)];
    return rgb;
  }
  calculatePBStyles(weight, idx) {
    const color = this.progressBarColor([255, 255, 0], [255, 0, 0], weight)
    let filled = document.getElementsByClassName('bar-progression').item(idx).getElementsByClassName('mat-progress-bar-fill')
    for (let i = 0; i < filled.length; ++i) {
      console.log(filled.item(i))
    }
    return true
  }

  removeUsersFromTeam(uid = undefined) {
    let uidTab = []
    if (uid) {
      uidTab.push(uid)
    } else {
      this.usersToRemove.forEach(
        user => {
          uidTab.push(user.id)
        }
      )
    }
    this.data.removeUsersFromTeam(JSON.stringify(uidTab), this.displayedTeamId).subscribe(
      data => console.log(data)
    )
    this.showEdit({teamId: this.displayedTeamId})
    this.allChecked = false
    this.usersToRemove = []
  }
}