import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ManagerGuard } from './manager.guard';
import { TeamsComponent } from './teams/teams.component';
import { ExternalConnectComponent } from './external-connect/external-connect.component';
import { StartcourseComponent } from './startcourse/startcourse.component';
import { ResultUserComponent } from './result-user/result-user.component';


const routes: Routes = [
  {path: '', redirectTo: '/login', pathMatch: 'full'},
  {path: 'login', component: LoginComponent},
  {path: 'dashboard', component: DashboardComponent, canActivate: [ManagerGuard]},
  {path: 'StartCourse', component: StartcourseComponent},
  {path: 'home', component: HomeComponent},
  {path: 'result-user', component:ResultUserComponent},
  {path: '**', component: PageNotFoundComponent}
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
