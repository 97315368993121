import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isObject } from 'util';
import { DataService } from '../data.service';

@Component({
  selector: 'app-result-user',
  templateUrl: './result-user.component.html',
  styleUrls: ['./result-user.component.scss']
})
export class ResultUserComponent implements OnInit {
private SelectedUserId ;
public ListOfObjectifsAccordingSelectedCourse =[]; 
public ListOfCorses=[] ;
public SelectedCourse : any;
public courseId : any;
public firstSelected : boolean;

public ResultSelectedCourse ;
public success_status = false ;
public complete_status = false ;
public has_objectives = false ;
public ShowObjectivesCourse = false ;
public hasSelectedObject =false ;
  constructor(private _dataService : DataService , private router : Router) {
    // this.SelectedCourse = {
    //   titre : "", 
    //   id : 0, 
    //   status : "complete",
    //   success: "failed",
    //   score : 0,
    //   time : 0,
    //   objectives : []  
    // }
   }

  ngOnInit() {
  this.router.routerState.root.queryParams.subscribe(data=>{
    console.log(data)
    this.SelectedUserId = data["current_user"]
    this.courseId = data["courseId"]
    this.firstSelected = data["firstSelected"]
  })
   // this.current_user = JSON.parse(localStorage.getItem("user"));
    this._dataService.getResults(this.SelectedUserId).subscribe(data=>{
      let obj = Object.values(data)[0];
      //console.log(this.ListOfCorses)
      let index = 0
      this.ListOfCorses = obj.filter(val=>  {
        console.log(val.objectives)
        if (val.language === "French") {
          if (this.courseId) {
            val.selected = this.courseId === val.id
            if (val.selected) {
              this.Refresh(val)
            }
          }
          if (this.firstSelected) {
            if (index === 0) {
              val.selected = true
              this.Refresh(val)
            }
          }
          ++index
          if (val.cmi_score_scaled){
            return {   
              titre : val.titre, 
              id : val.id, 
              status : val.cmi_completion_status,
              success: val.cmi_success_status,
              score : val.cmi_score_scaled,
              time : val.total_time,
              objectives : val.objectives,
              selected: val.selected
            } 
          } else 
          return {
            titre : val.titre, 
            id : val.id, 
            status : val.cmi_completion_status,
            success: val.cmi_success_status,
            score : 0,
            time : val.total_time,
            objectives : val.objectives,
            selected: val.selected
          }
        }
      })
    })
    console.log(this.ListOfCorses);
    this.hasSelectedObject = true ;

  }
isPassed() 
{
  console.log(this.SelectedCourse)
  if(this.SelectedCourse.success =="passed")
  this.success_status = true 
  else 
  this.success_status = false
}
isComplete() 
{

  if(this.SelectedCourse.status =="complete" || this.SelectedCourse.status =="completed" )
  this.complete_status = true 
  else 
  this.complete_status = false
}
Refresh(sc)
{
  this.SelectedCourse = sc ;
  if (this.SelectedCourse.objectives) {
    if (this.SelectedCourse.objectives.length > 0)
      this.has_objectives = true ;
  }
  else 
  this.has_objectives = false ;
  this.isComplete()
  this.isPassed() 
}
ShowObjectives(){
  console.log("dlksjkjd")
  this.ShowObjectivesCourse = !this.ShowObjectivesCourse ; 
}
backToHome() {
  this.router.navigate(['/home'])
}
}
