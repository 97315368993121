import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  user = undefined;

  constructor() { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem("user"));
  }

}
