import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  text = new EventEmitter<String>()

  constructor() { }

  setText(text: String) {
    this.text.emit(text);
  }
}
