import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-completion-chart',
  templateUrl: './completion-chart.component.html',
  styleUrls: ['./completion-chart.component.scss']
})
export class CompletionChartComponent implements OnInit {

  @Input() data  = new Input();
  tabs = ["dashboard.unstarted", "dashboard.started", "dashboard.finished"]

  constructor() { }

  ngOnInit() {
    console.log(this.data)
  }

}
