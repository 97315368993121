import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private translate: TranslateService) {
    switch (navigator.language) {
      case "fr-FR":
        translate.setDefaultLang('fr');
        break;
      case "en-US":
        translate.setDefaultLang('en');
        break;
      default:
        translate.setDefaultLang('fr');
        break;
    }
  }
}
