import { analyzeFileForInjectables } from '@angular/compiler';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DataService } from '../data.service';

export interface ExportCSV {
  uid: string,
  startdate,
  enddate,
  filterTeamsSelector: string,
  filterModulesSelector: string,
  filterUserFailed,
  filterUserStartCourse,
  filterUsersList,
  filterModulesList
}

@Component({
  selector: 'app-dashboard-export',
  templateUrl: './dashboard-export.component.html',
  styleUrls: ['./dashboard-export.component.scss']
})
export class DashboardExportComponent implements OnInit {

  @Output() closed = new EventEmitter();

  constructor(
    private data: DataService
  ) { 
  }
  csvData: ExportCSV = {
    uid: JSON.parse(localStorage.getItem("user")).idUser,
    startdate: this.setStartDate(),
    enddate: new Date(Date.now()),
    filterTeamsSelector: "ALL_TEAMS",
    filterModulesSelector: "ALL_MODULES",
    filterUserFailed: false,
    filterUserStartCourse: false,
    filterUsersList: [],
    filterModulesList: []
  }

  coursesLoaded: boolean = false;
  teamsLoaded: boolean = false;
  usersLoaded: boolean = false;
  showModulesList: boolean = false;
  showTeamsList: boolean = false;
  courseList = []
  teamList = []
  selectedCourse = {
    modules: []
  }
  selectedTeam = {
    users: []
  }

  setStartDate() {
    let actual = new Date(Date.now())
    let startDate
    if (actual.getMonth() !== 0) {
      startDate = new Date(actual.setMonth(actual.getMonth() - 1))
    } else {
      startDate = new Date(actual.setFullYear(actual.getFullYear() - 1, 11))
    }
    return startDate
  }

  ngOnInit() {
    this.data.getAllCourses(JSON.parse(localStorage.getItem("user")).idUser).subscribe(
      data => {
        console.log(Object.values(data));
        this.courseList = Object.values(data);
        this.courseList.pop();
        this.courseList.pop();
        this.coursesLoaded = true
      }
    )

    this.data.getTeam(JSON.parse(localStorage.getItem("user")).idUser).subscribe(
      data => {
        console.log(data["teams"])
        this.teamsLoaded = true
        data["teams"].forEach(team => {
          this.data.getUserByTeam(JSON.parse(localStorage.getItem("user")).idUser, team.teamId).subscribe(result => {
            team.users = result["users"];
            this.teamList.push(team);
          });
        });
        this.usersLoaded = true
      })
  }

  generateCSV() {
    let startDate = new Date(this.csvData.startdate)
    let formattedStartDate = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`
    let endDate = new Date(this.csvData.enddate)
    let formattedEndDate = `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`
    this.csvData.startdate = formattedStartDate
    this.csvData.enddate = formattedEndDate
    this.csvData.filterUserFailed ? this.csvData.filterUserFailed = "on" : this.csvData.filterUserFailed = "off"
    this.csvData.filterUserStartCourse ? this.csvData.filterUserStartCourse = "on" : this.csvData.filterUserStartCourse = "off"
    this.csvData.filterModulesList = JSON.stringify(this.csvData.filterModulesList)
    this.csvData.filterUsersList = JSON.stringify(this.csvData.filterUsersList)
    if (this.dateDiffInDays(startDate, endDate) < 0) {
      alert('La date de fin est antérieure à la date de début !')
    } else if(this.dateDiffInDays(endDate, new Date(Date.now())) < 0) {
      alert('La date de fin est postérieure à aujourd\'hui !')
    } else {
      this.data.generateCSVOnDashboard(this.csvData).subscribe(
        data => {
          console.log(data);
          const fileName = `Export ${startDate.getDate()}\/${startDate.getMonth() + 1}\/${startDate.getFullYear()} - ${endDate.getDate()}\/${endDate.getMonth() + 1}\/${endDate.getFullYear()}.csv`
          this.downloadFile(data, fileName)
        }
      )
    }
    this.csvData.startdate = startDate
    this.csvData.enddate = endDate
    this.csvData.filterUserFailed = this.csvData.filterUserFailed !== "off"
    this.csvData.filterUserStartCourse = this.csvData.filterUserStartCourse !== "off"
    this.csvData.filterModulesList = JSON.parse(this.csvData.filterModulesList)
    this.csvData.filterUsersList = JSON.parse(this.csvData.filterUsersList)
  }

  downloadFile(data: any, fileName) {
    const a = document.createElement('a');
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
}


  dateDiffInDays(a, b) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;

    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }

  showModules(event) {
    this.showModulesList = event.value === "SPECIFIC_MODULES";
  }

  showTeams(event) {
    this.showTeamsList = event.value === "SPECIFIC_TEAMS";
  }

  addModuleThroughCourse(modules, event) {
    if (event.checked) {
      modules.forEach(
        module => {
          this.csvData.filterModulesList.push(module.moduleId)
        }
      )
    } else {
      this.csvData.filterModulesList = this.csvData.filterModulesList.filter(
        id => {
          let isPresent = false
          modules.forEach(module => {
            if (module.moduleId === id) isPresent = true
          });
          if (!isPresent) return id
        }
      )
    }
  }

  setSelectedCourse(course) {
    this.selectedCourse = course
  }

  isChecked(moduleId) {
    let isChecked = false
    this.csvData.filterModulesList.forEach(id => {
      if (id === moduleId) isChecked = true
    });
    return isChecked
  }

  isCheckedCourse(modules) {
    let checked = []
    if (modules.length === 0) return false
    this.csvData.filterModulesList.forEach(id => {
      let isChecked = false
      modules.forEach(module => {
        if (id === module.moduleId) {
          isChecked = true
          checked.push(isChecked)
        }
      });
    });
    return checked.length > 0
  }

  addModule(moduleId, event) {
    event.checked ? this.csvData.filterModulesList.push(moduleId) : this.csvData.filterModulesList = this.csvData.filterModulesList.filter(id => id !== moduleId)
  }

  setSelectedTeam(team) {
    this.selectedTeam = team
  }

  addUserThroughTeam(users, event) {
    if (event.checked) {
      users.forEach(
        user => {
          this.csvData.filterUsersList.push(user.id)
        }
      )
    } else {
      this.csvData.filterUsersList = this.csvData.filterUsersList.filter(
        id => {
          let isPresent = false
          users.forEach(user => {
            if (user.id === id) isPresent = true
          });
          if (!isPresent) return id
        }
      )
    }
  }

  isCheckedUser(userId) {
    let isChecked = false
    this.csvData.filterUsersList.forEach(id => {
      if (id === userId) isChecked = true
    });
    return isChecked
  }

  isCheckedTeam(users) {
    let checked = []
    if (users.length === 0) return false
    this.csvData.filterUsersList.forEach(id => {
      let isChecked = false
      users.forEach(user => {
        if (id === user.id) {
          isChecked = true
          checked.push(isChecked)
        }
      });
    });
    return checked.length > 0
  }

  addUser(userId, event) {
    event.checked ? this.csvData.filterUsersList.push(userId) : this.csvData.filterUsersList = this.csvData.filterUsersList.filter(id => id !== userId)
  }

  emitCancel() {
    this.closed.emit("cancel")
  }

}
