import { Component, OnInit } from '@angular/core';
import { MatDialog, throwToolbarMixedModesError } from '@angular/material';
import { DataService } from '../data.service';

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.scss']
})
export class CourseComponent implements OnInit {

  cards = [];
  modules = [];
  isDeleteActive = false;
  isModuleShowed = false;
  languages = [
    "Français",
    "English",
    "Deutsch"
  ]
  creationState = false;
  moduleList = [];

  constructor(
    private dataservice: DataService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    const user = JSON.parse(localStorage.getItem("user"));
    let coursesGetted = true;
    if (coursesGetted) {
      coursesGetted = false;
      this.dataservice.getAllCourses(user.idUser).subscribe(
        data => {
          console.log(Object.values(data));
          this.cards = Object.values(data);
          this.cards.pop();
          this.cards.pop();
          coursesGetted = true;
        }
      )
    }
    this.dataservice.getAllModules(user.idUser).subscribe(
      data => {
        console.log(Object.values(data));
        this.modules = Object.values(data);
        this.modules.pop();
        this.modules.pop();
        this.modules = this.modules.filter(card => {
          if (card.firstUploadDate !== "") {
            return card;
          }
        });
        this.modules = this.modules.sort((a, b) => {
          let dateA = new Date(a.firstUploadDate);
          let dateB = new Date(b.firstUploadDate);
          if (dateA < dateB)
            return 1;
          if (dateA > dateB)
            return -1;
          return 0;
        })
      }
    )
  }

  replace(moduleId) {
    // this.alertImport(moduleId)
  }

  toggleDeleteStatus() {
    this.isDeleteActive = !this.isDeleteActive;
  }

  toggleCreationState() {
    this.creationState = !this.creationState;
  }

  delete(id) {
    // this.dataservice.deleteCourse(id).subscribe(
    //   data => console.log(`Course number ${id} succefully deleted !`)
    // )
  }

  showModuleSelection() {
    this.isModuleShowed = !this.isModuleShowed;
  }

  addModuleToList(event) {
    console.log(event)
    event.checked ? this.moduleList.push(event.source.value) : this.deleteModuleFromList(event.source.value.id)
  }

  deleteModuleFromList(key: string) {
    this.moduleList.forEach((value,index)=>{
        if(value.id==key) this.moduleList.splice(index,1);
    });
} 

}